import { useEffect, useState } from "react";
import Request from "../../../../api/Requests";
import Loading from "../../../../components/Loading";
import Menu from "../../Menu";
import { useNavigate, Link, useParams } from "react-router-dom";
import PromoterStats from "./Stats/PromoterStats";
import InviteList from "./InviteList/InviteList";
import TablesList from "./tables/TablesList";
const EventManager = ({ tab }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState(null)
    let paramsUrl = useParams();

    useEffect(() => {
        getEvent()
    }, [])

    useEffect(() => {
        getEvent()
    }, [tab])

    const getEvent = async () => {
        setIsLoading(true)
        const response = await Request('reserved-area/get-promoter-info-event', 'GET', { id: paramsUrl.id_events, tab: tab }, true);
        if (response && response.status) {
            setState(response.data)
        } else {

        }
        setIsLoading(false)
    }
    const EventViewer = () => {
        return (
            <>

                <div className={`row event-item`}>
                    <div className="col-2">
                        <img src={state.event.banner} alt={state.event.name} style={{ height: '50px', width: 'auto' }} className="img-fluid event-img" />
                    </div>
                    <div className="col-10">
                        <h5 className="text-light">{state.event.name}</h5>
                        <div className="mt-3" style={{ fontSize: '0.9rem' }}>
                            {state.event.date_formated}
                            <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                            <span style={{ color: '#939393' }}>{state.event.hour_start} - {state.event.hour_end}</span>
                        </div>

                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12 menu-container" >
                        <ul className="menu-list" >
                            <li onClick={() => navigate(`/my/promoters-dashboard/event/${paramsUrl.id_events}`)} className={`${tab === 'home' ? 'active' : null}`}><i className="fa-solid fa-chart-line me-2"></i>Estatisticas</li>
                            <li onClick={() => navigate(`/my/promoters-dashboard/event/${paramsUrl.id_events}/invite-guests`)} className={`${tab === 'invite-guests' ? 'active' : null}`}><i className="fa-solid fa-user-plus me-2"></i>Lista Convidados</li>
                            <li onClick={() => navigate(`/my/promoters-dashboard/event/${paramsUrl.id_events}/tables`)} className={`${tab === 'tables' ? 'active' : null}`}><i className="fa-solid fa-crown  me-2"></i>Reserva de Mesas</li>

                        </ul>
                    </div>
                </div>
            </>
        )
    }


    const ContentManager = () => {
        return (
            <>
                {tab == 'home' ?
                    (
                        <PromoterStats stats={state.stats} />
                    ): null}
                {tab == 'invite-guests' ?
                    (
                        <InviteList info={state.inviteList} />
                    ): null}
                {tab == 'tables' ?
                    (
                        <TablesList info={state.tables} refreshData={() => getEvent()} />
                    ): null}
            </>
        )
    }


    return (isLoading ? <Loading /> :
        <div className="container-fluid">
            <div className="row mb-3 justify-content-center">
                <div className="col-12 col-sm-6">
                    <div className="position-relative z-1">
                        <div className="box-tables mt-5" style={{padding: '5px'}}>

                            <Menu tab="promoters-dashboard" />
                            <div className="row mt-3 ">
                                <div className="col-12 ">
                                    <EventViewer />
                                </div>
                            </div>
                            <ContentManager />



                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default EventManager;