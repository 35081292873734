import React, { useEffect, useState,useCallback } from 'react';
import { EmbeddedCheckoutProvider , EmbeddedCheckout} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51QyeEvF854lsk2434hVdjFzrxcrOom4z6SN04bqaaDb4UPpwNpI2Xq8ojCKgKKzcYTgpfxLT0dycuEJjIAbdDJeL004vugp7Kw');

const CompletePayment = ({ show, params }) => {

    console.log(params)


    const options = {
        clientSecret: params.transaction.transaction_payment_id,
    };

    return !show || !params ? null :  (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    )
    // return !show || !params ? null : (
    //     <div>
    //         <Elements
    //             stripe={stripePromise}
    //             options={{
    //                 clientSecret: params.transaction.transaction_payment_id,
    //                 appearance: {
    //                     theme: 'night',
    //                     locale: 'pt',
    //                 }
    //             }}
    //         >
    //             <PaymentElement
    //                 options={{
    //                     defaultValues: {
    //                         billingDetails: {
    //                             name: 'John Doe',
    //                             phone: '888-888-8888',
    //                             address: {
    //                                 country: 'PT',
    //                             }
    //                         },
    //                     },
    //                 }}
    //             />
    //         </Elements >

    //     </div>

    // );
};

export default CompletePayment;
