import React, { useState, useEffect } from 'react';

const Countdown = ({ initialSeconds }) => {
  const [time, setTime] = useState(initialSeconds);

  useEffect(() => {
    setTime(initialSeconds); // Atualiza o tempo quando a prop muda
  }, [initialSeconds]);

  useEffect(() => {
    if (time <= 0) return; // Evita criar intervalos desnecessários

    const intervalId = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar ou atualizar
  }, [time]); // Dependendo do tempo para reagir a mudanças

  // Formatação do tempo em mm:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return <div style={{ fontSize: '4rem', width: '100%' }}>{formatTime(time)}</div>;
};

export default Countdown;
