import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import TablesRequests from "./TablesRequests";
import Loading from "../../../components/Loading";
import './TablesList.css';
import { toast, ToastContainer } from 'react-toastify';
import { SelectCountries, Input } from '../../Layout/Forms/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import { event } from "react-ga";

const TableEvent = ({ type }) => {

    const [isLoading, setIsLoading] = useState(true);
    const urlParams = useParams();
    const navigate = useNavigate();
    const refCaptcha = useRef(null);
    const searchParams = new URLSearchParams(window.location.search);
    const isAppWebView = searchParams.get('appWebView') === 'true';
    const [state, setState] = useState({
        event: {

        },
        form: {
            phone_country: '351',
            phone_number: '',
            email: '',
            recaptcha: ''
        },
        countryList: []
    })
    const [isVerified, setIsVerified] = useState(false);
    const urlPromoterTable = `/${type == `venue` ? `v` : `u`}/${urlParams.slug}/${urlParams.event}`

    useEffect(async () => {
        setIsLoading(true)
        let response = await TablesRequests.getInfoEventTable(urlParams, type);
        if (response && response.status == true) {
            if (response.data.table.status !== "PENDING") {
                navigate(urlPromoterTable);
                //console.log(urlPromoterTable, urlParams)
            }
            setState({
                ...state,
                countryList: response.data.countryList,
                event: response.data.event,
                table: response.data.table,

            })
        } else {
            navigate(urlPromoterTable);
        }
        setIsLoading(false)
    }, [])


    const handleChangeInputs = (e) => {

        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }
    const handleRecaptchaChange = (token) => {
        setIsVerified(true);
        handleChangeInputs({
            target: {
                name: "recaptcha",
                value: token
            }
        })
    };

    const seriaizeCountryList = () => {
        if (state.countryList && state.countryList.length > 0)
            return state.countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }

    const handlerSubmitTableReservation = async (e) => {
        // setIsLoading(true);
        e.preventDefault();

       
        // console.log(state.form.phone_number.length == 0);
        // return;

        let errorArray = {};
        let error = false;
        if (state.form.phone_number.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    phone_number: [
                        'Preenche o Nº Telemóvel'
                    ]
                }
            }
            error = true;
        }

        if (state.form.email.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    email: [
                        'Campo obrigatório'
                    ]
                }
            }
            error = true;
        }

        if (state.form.name.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    name: [
                        'Campo obrigatório'
                    ]
                }
            }
            error = true;
        }

        if (error) {
            toast.error("Preencha os campos obrigatórios", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setState({
                ...state,
                errors: errorArray
            })
            // setIsLoading(false);
            return false;
        }


        // Chamar o reCAPTCHA para obter o token de verificação
        //const recaptchaValue = refCaptcha.current.getValue();
        const recaptchaValue = await refCaptcha.current.executeAsync();
        if (recaptchaValue) {
            submitForm(recaptchaValue);
        } else {
            toast.error("Verifique o reCAPTCHA antes de submeter o formulário.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const submitForm = async (recaptchaValue) => {
        setIsLoading(true);
        const formWithToken = { ...state.form, recaptcha: recaptchaValue }
        let response = await TablesRequests.submitReserveTable({ ...urlParams, ...formWithToken }, type);
        if (response && response.status == true) {
            const urlPromoterTable = `/${type == `venue` ? `v` : `u`}/${urlParams.slug}/${state.event.slug}/tables/${state.table.id}`
            navigate(`${urlPromoterTable}/transaction/${response.data.transaction_id}`);
        } else {
            if (response.msg && response.msg.length > 0) {
                toast.error(response.msg, {
                    position: "top-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }
        setIsLoading(false)
    }

    return (
        isLoading ? <Loading /> : (
            <div className="container-fluid">
                <div className="position-absolute opacity-25 dark:opacity-25" style={{ minHeight: '100vh', background: `url('${state.event.image}') center center / cover`, left: '-30%', top: '-30%', width: '100%', filter: 'blur(100px)' }}>
                </div>
                <div className="container position-relative z-1">
                    <div className="box-tables mt-5">
                        <div className={`row mb-3 ${isAppWebView ? 'd-none' : ''}`}>
                            <div className="col-12" style={{ width: '64px' }}>
                                <Link to={urlPromoterTable} >
                                    <div className="table-price text-center" >
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-3">
                                <div className="row">
                                    <div className="col-12 ">
                                        <img src={state.event.image} className="img-fluid img-event-single" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-9">
                                <h1>{state.event.name}</h1>
                                <div className="row mt-3 mt-sm-0 ">
                                    <div className="col-12">
                                        {state.event.date_formated}
                                        <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                                        <span style={{ color: '#939393' }}>{state.event.hour_start} - {state.event.hour_end}</span>
                                    </div>
                                </div>
                                <div className="row mt-3 ">
                                    <div className="col-12">
                                        <div className="box-single-table noclick" style={{ borderColor: state.table.color }}>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div className="table-title">Mesa {state.table.name}</div>
                                                    <div className="table-description">
                                                        {state.table.description}
                                                    </div>
                                                </div>
                                                <div className="align-self-center">
                                                    <div className="table-price">
                                                        {state.table.base_price}€
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="row mt-3 position-relative z-2" >
                                    <div className="col-12">
                                        <div className="box-single-table noclick" style={{ borderWidth: 0 }}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <h3>Check-out</h3>
                                                </div>
                                            </div>
                                            <form onSubmit={handlerSubmitTableReservation} >
                                                <div className="row">
                                                    <div className=" col-12 col-sm-7 ">
                                                        <label className="form-label">Dados da reserva</label>
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <Input
                                                                    label="Nome"
                                                                    name="name"
                                                                    type="text"
                                                                    value={state.form.name}
                                                                    onChange={(e) => handleChangeInputs(e)}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault();
                                                                        handleChangeInputs({
                                                                            target: {
                                                                                name: e.target.name,
                                                                                value: e.clipboardData.getData("Text"),
                                                                            },
                                                                        });
                                                                    }}
                                                                    errors={state.errors}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 d-grid  mx-auto ">

                                                                <div style={{ display: 'flex', alignItems: 'flex-end' }}>

                                                                    <SelectCountries
                                                                        label="&nbsp;"
                                                                        name="phone_country"
                                                                        value={state.form.phone_country}
                                                                        onChange={(e) => handleChangeInputs(e)}
                                                                        options={seriaizeCountryList()}
                                                                        errors={state.errors}

                                                                    />
                                                                    <div className="ms-1 w-100">
                                                                        <Input
                                                                            label="Nº Telemóvel"
                                                                            name="phone_number"
                                                                            type="number"
                                                                            value={state.form.phone_number}
                                                                            onChange={(e) => handleChangeInputs(e)}
                                                                            onPaste={(e) => {
                                                                                e.preventDefault();
                                                                                handleChangeInputs({
                                                                                    target: {
                                                                                        name: e.target.name,
                                                                                        value: e.clipboardData.getData("Text"),
                                                                                    },
                                                                                });
                                                                            }}
                                                                            errors={state.errors}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <Input
                                                                    label="Email"
                                                                    name="email"
                                                                    type="text"
                                                                    value={state.form.email}
                                                                    onChange={(e) => handleChangeInputs(e)}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault();
                                                                        handleChangeInputs({
                                                                            target: {
                                                                                name: e.target.name,
                                                                                value: e.clipboardData.getData("Text"),
                                                                            },
                                                                        });
                                                                    }}
                                                                    errors={state.errors}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <Input
                                                                    label="Contribuinte"
                                                                    name="contribuinte"
                                                                    type="text"
                                                                    value={state.form.contribuinte}
                                                                    onChange={(e) => handleChangeInputs(e)}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault();
                                                                        handleChangeInputs({
                                                                            target: {
                                                                                name: e.target.name,
                                                                                value: e.clipboardData.getData("Text"),
                                                                            },
                                                                        });
                                                                    }}
                                                                    errors={state.errors}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* <div className="row">
                                                            <div className="col-12 ">
                                                                <Input
                                                                    label="Repita o Email"
                                                                    name="email_2"
                                                                    type="text"
                                                                    value={state.form.email_2}
                                                                    onChange={(e) => handleChangeInputs(e)}
                                                                    errors={state.errors}
                                                                />
                                                            </div>
                                                        </div> */}


                                                        <div className="row" style={{ marginTop: '30px' }}>
                                                            <div className="col-12">
                                                                Método pagamento
                                                                <div className="list-group list-group-radio d-grid gap-2 border-0">
                                                                    <div className="position-relative">
                                                                        <input style={{ zIndex: 999, top: '35%' }} className="form-check-input position-absolute  end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" id="paumento_method_mbway" value="" onChange={() => false} checked={'checked'} />
                                                                        <label className="list-group-item py-3 pe-5" htmlFor="paumento_method_mbway" style={{ backgroundColor: '#0a1116', color: '#dee2e6' }}>
                                                                            <strong className="fw-semibold">
                                                                                <img src="/img/payments/mbway.png" className="img-fluid" style={{ height: '32px', width: 'auto' }} />
                                                                            </strong>
                                                                            <span className="mt-1 d-block d-none small opacity-75">Este método de pagamento têm um valor máximo de <u>750€</u> por transferência imposto pela SIBS.</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        


                                                        <div className="row" style={{ marginTop: '30px' }}>
                                                            <div className="col-12">
                                                                <ReCAPTCHA size="invisible" ref={refCaptcha} theme="dark" sitekey="6LeJQrwpAAAAAHSzvB8Z_eA8SBZk9YBOBwBU6qyz" onChange={handleRecaptchaChange} />

                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="col-12 col-sm-5 ">
                                                        <h3>Resumo</h3>
                                                        <table className="table table-dark table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <td>Descrição</td>
                                                                    <td>Valor</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* <tr>
                                                                    <td>Percentagem Pré-Pagamento</td>
                                                                    <td>{state.table.percentage_pre_payment}%</td>
                                                                </tr> */}
                                                                <tr>
                                                                    <td>
                                                                        Mesa {state.table.name}
                                                                        <div style={{ fontSize: '8px' }}>
                                                                            Percentagem Pré-Pagamento: {state.table.percentage_pre_payment}%
                                                                        </div>
                                                                    </td>
                                                                    <td>{state.table.pre_payment}€</td>
                                                                </tr>
                                                                <tr style={{ fontSize: '10px' }}>
                                                                    <td>Custo operação</td>
                                                                    <td>{state.table.transaction_fee}€</td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td>Total</td>
                                                                    <td>{state.table.total_price}€</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="2">
                                                                        <button type="submit" className="btn btn-primary d-block w-100" >Confirmar reserva<i className="fa-solid fa-arrow-right ms-2"></i></button>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    )
}

export default TableEvent;