import { useState, useEffect } from "react";

import { toast } from 'react-toastify';
import Request from "../../../../../api/Requests";
import { useParams } from "react-router-dom";


import FormTableReservation from "./_formTableReservation";

const TablesList = ({ info, refreshData }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState(null)
    
    const [modalTableReservation, setModalTableReservation] = useState({
        isVisible: false,
        data: null
    });
    let paramsUrl = useParams();
    useEffect(() => {
        
        setState(info)
        
    }, [info])


    // useEffect(() => {
    //     if (formTable.payment_phone_number.length == 0 && formTable.phone_number.length == 9) {
    //         setformTable({
    //             ...formTable,
    //             payment_phone_number: formTable.phone_number
    //         });
    //     }
    // }, [formTable.phone_number])

    const closeModal = () => setModalTableReservation({
        ...modalTableReservation,
        isVisible: false,
        data: null
    });

    

    

    const handlerActionTable = async (id) => {
        setIsLoading(true);
        const response = await Request('reserved-area/get-promoter-table-status', 'POST', { id_events: paramsUrl.id_events, id: id }, true);
        if (response && response.status) {
            if (response.data.table.status === "RESERVED") {
                toast.error("Esta mesa encontra-se reservada", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else if (response.data.table.status === "HOLD") {
                toast.warning("Esta mesa encontra-se em processo de pagamento. Poderá demorar até 6 minutos a desbloquear a mesma ou a passar a reservado", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else {
                setModalTableReservation({
                    ...modalTableReservation,
                    isVisible: true,
                    data: response.data
                })
            }

        }
        setIsLoading(false);
    }

    const TableList = () => {
        return state.list.map((item) => {
            return (
                <div className="col-12 col-md-6 mb-4" key={item.id} onClick={() => handlerActionTable(item.id)}>
                    <div className="block-mesa h-100" >
                        <div className="block-content" style={{ borderLeft: `5px solid ${item.color}` }}>
                            <div className="block-title">
                                <h3>Mesa: {item.name}</h3>
                            </div>
                            <div className="block-details mt-3">
                                {item.base_price}<i className="fa-solid fa-euro-sign ms-1 me-2"></i>
                                <i className="fa-solid fa-users ms-2 me-1"></i>{item.numberOfGuests}


                            </div>
                        </div>
                        <div className={`block-footer ${item.status.toLowerCase()}`}>
                            <div className="ms-3">
                                Estado: <span>{item.status_text}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    

    return (!state ? null : (
        <>
            <hr />
            <div className="row mt-2">
                <div className="col">
                    <button type="button" className="btn btn-primary" onClick={() => refreshData()}><i className="fa-solid fa-refresh me-3"></i>Atualizar Lista</button>
                </div>
            </div>
            <div className="row mt-3">
                <TableList />
            </div>
            <FormTableReservation 
                isVisible={modalTableReservation.isVisible} 
                data={modalTableReservation.data} 
                closeModal={closeModal} 
                refreshData={refreshData}   
                countryList={state.countryList}
            />
        </>
    ))
}


export default TablesList;