import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import { Input, SelectCountries } from "../../../../Layout/Forms/Form"
import BottomSheetModal from "../../../../Layout/BottomSheetModal/BottomSheetModal";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Request from "../../../../../api/Requests";

const FormTableReservation = ({ isVisible, data, closeModal, refreshData, countryList}) => {
    const [isLoading, setIsLoading] = useState(true);
    let paramsUrl = useParams();
    const [formTable, setformTable] = useState({
        "name": '',
        "email": '',
        "phone_country": '+351',
        "phone_number": '',
        "contribuinte": '',
        "payment_phone_number": '',
        "total": 0,
        "percentage_pre_payment": 100,
        "errors": []
    })

    useEffect(() => {
        
        if (isVisible && data && data?.table) {
            setformTable({
                ...formTable,
                percentage_pre_payment: data.table.percentage_pre_payment
            });
        }
    }, [data, isVisible]);

    const handleChangeInputs = (e) => {
        console.log(e.target.name, e.target.value);
        setformTable({
            ...formTable,
            [e.target.name]: e.target.value
        });
    }


    useEffect(() => {
        if (data && data?.table) {
            handlerCalculateTotal(data.table.base_price);
        }
    }, [formTable.percentage_pre_payment]);


    const handlerCalculateTotal = (value) => {
        const prePaymentPercentage = parseFloat(formTable.percentage_pre_payment) / 100;
        //const transactionFeePercentage = parseFloat(formTable.transaction_fee) / 100;
    
        const prePaymentAmount = parseFloat(value) * prePaymentPercentage;
        const totalAmount = prePaymentAmount;
        console.log(totalAmount, parseFloat(value), prePaymentPercentage, parseFloat(formTable.percentage_pre_payment));
        setformTable({
            ...formTable,
            total: totalAmount
        });
    };
    

    const seriaizeCountryList = () => {
        if (countryList && countryList.length > 0)
            return countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }

    const theme = createTheme({
        components: {
            MuiSlider: {
                styleOverrides: {
                    markLabel: {
                        color: '#fff',
                    },
                },
            },
        },
        palette: {
            primary: {
                main: "#fff",

            },
            secondary: {
                main: '#11cb5f',
            }
        },
        markLabel: {
            color: 'red',
        },


    });

    const marksPercentagePrePayment = [
        {
            value: 0,
            label: "0",
        },
        {
            value: 25,
            label: "25",
        },
        {
            value: 50,
            label: "50",
        },
        {
            value: 75,
            label: "75",
        },
        {
            value: 100,
            label: "100",
        },
    ];


    const handlerSubmitReserveTable = async (id) => {
        let errorArray = {};
        let error = false;
        if (formTable.phone_number.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    phone_number: [
                        'Preencha o Nº Telemóvel'
                    ]
                }
            }
            error = true;
        }

        if (formTable.name.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    name: [
                        'Insira o seu nome'
                    ]
                }
            }
            error = true;
        }
        if (formTable.email.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    email: [
                        'Insira o seu email'
                    ]
                }
            }
            error = true;
        }

        if (error) {
            toast.error("Preencha os campos obrigatórios", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setformTable({
                ...formTable,
                errors: errorArray
            })
            // setIsLoading(false);
            return false;
        }

        setIsLoading(true)
        const response = await Request('reserved-area/reserve-table-promoter', 'POST', { ...formTable, id: id, id_events: paramsUrl.id_events }, true);
        if (response && response.status) {

            // if (gridInvitesRef.current && gridInvitesRef.current.api) {
            //     gridInvitesRef.current.api.setRowData(updatedList);
            // }
            toast.success("Pedido de pagamento criado com sucesso.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            refreshData()
            closeModal()
        } else {
            toast.error("Erro a criar transação, solicite suporte. info@nightspot.pt.", {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        setIsLoading(false)
    }


    return (
        <BottomSheetModal isVisible={isVisible} onClose={closeModal} height="90vh">
            {!data ? null : (
                <div style={{ padding: '10px 20px' }}>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label="Nome"
                                className="form-control form-input input-modal"
                                name="name"
                                type="text"
                                value={formTable.name}
                                onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                onChange={(e) => handleChangeInputs(e)}
                                errors={formTable.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label="Email"
                                className="form-control form-input input-modal"
                                name="email"
                                type="text"
                                value={formTable.email}
                                onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                onChange={(e) => handleChangeInputs(e)}
                                errors={formTable.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-grid  mx-auto ">
                            <div className="d-flex">
                                <div className="mt-2">
                                    <SelectCountries
                                        label="&nbsp;"
                                        name="phone_country"
                                        value={formTable.phone_country}
                                        onChange={(e) => handleChangeInputs(e)}
                                        options={seriaizeCountryList()}
                                        errors={formTable.errors}
                                    />
                                </div>
                                <div className="flex-grow-1 ms-1">
                                    <Input
                                        label="Nº Telemóvel"
                                        className="form-control form-input input-modal"
                                        name="phone_number"
                                        type="number"
                                        value={formTable.phone_number}
                                        onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                        onChange={(e) => handleChangeInputs(e)}
                                        errors={formTable.errors}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <div className="card cards-bg-form" style={{ backgroundColor: '#00000063' }}>
                                <h5 className="card-header"><i className="fa-solid fa-money-check-dollar me-3"></i>Pagamento</h5>
                                <div className="card-body">

                                    Método pagamento
                                    <div className="list-group list-group-radio d-grid gap-2 border-0">
                                        <div className="position-relative">
                                            <input style={{ zIndex: 999, top: '35%' }} className="form-check-input position-absolute  end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" id="paumento_method_mbway" value="" onChange={() => false} checked={'checked'} />
                                            <label className="list-group-item py-3 pe-5" htmlFor="paumento_method_mbway" style={{ backgroundColor: '#0a1116', color: '#dee2e6' }}>
                                                <strong className="fw-semibold">
                                                    <img src="/img/payments/mbway.png" className="img-fluid" style={{ height: '32px', width: 'auto' }} />
                                                </strong>
                                                <span className="mt-1 d-block small opacity-75">Este método de pagamento têm um valor máximo de <u>750€</u> por transferência imposto pela SIBS.</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <Input
                                                label="Nº Telemóvel MbWay"
                                                className="form-control form-input input-modal"
                                                name="payment_phone_number"
                                                type="number"
                                                value={formTable.payment_phone_number}
                                                onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                                onChange={(e) => handleChangeInputs(e)}
                                                errors={formTable.errors}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-11">
                                            <label className="form-label">
                                                Percentagem Pré-Pagamento
                                            </label>
                                            <ThemeProvider theme={theme}>
                                                <Slider
                                                    getAriaLabel={() => "Percentagem Pré-Pagamento"}
                                                    value={formTable.percentage_pre_payment}
                                                    name="percentage_pre_payment"
                                                    onChange={handleChangeInputs}
                                                    min={0}
                                                    max={100}
                                                    step={25}
                                                    color="primary"
                                                    valueLabelDisplay="on"
                                                    marks={marksPercentagePrePayment}
                                                />
                                            </ThemeProvider>
                                        </div>
                                        <div className='col-5'>
                                            Valor Mesa
                                            <div className='p-3 text-end' style={{ backgroundColor: '#0d1012', fontSize: '1.3rem', borderRadius: '8px' }}>
                                                {(parseFloat(data.table.base_price)).toFixed(2)}€
                                            </div>
                                        </div>
                                        <div className='col-7'>
                                            Valor a ser pago online <span style={{ fontWeight: 200, fontSize: '0.5rem' }}>(Incluí taxa de pagamento)</span>
                                            <div className='p-3 text-end' style={{ backgroundColor: '#0d1012', fontSize: '1.3rem', borderRadius: '8px' }}>
                                                {/* {((parseFloat(data.table.base_price) * (parseFloat(data.table.percentage_pre_payment) / 100)) * (1 + (data.table.transaction_fee / 100))).toFixed(2)}€ */}
                                                {(formTable.total * (1 + (data.table.transaction_fee / 100))).toFixed(2)}€
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            Valor a ser pago no local
                                            <div className='p-3 text-end' style={{ backgroundColor: '#0d1012', fontSize: '1.3rem', borderRadius: '8px' }}>
                                                {/* {((parseFloat(data.table.base_price) - (parseFloat(data.table.base_price) * (parseFloat(data.table.percentage_pre_payment) / 100)))).toFixed(2)}€ */}
                                                {/* {((parseFloat(data.table.base_price) - parseFloat(formTable.total)) * (1 + (data.table.transaction_fee / 100))).toFixed(2)}€ */}
                                                {((parseFloat(data.table.base_price) - parseFloat(formTable.total)) ).toFixed(2)}€
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-12">
                                            <Input
                                                label="Contribuinte"
                                                className="form-control form-input input-modal"
                                                name="payment_phone_number"
                                                type="number"
                                                value={formTable.contribuinte}
                                                onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                                onChange={(e) => handleChangeInputs(e)}
                                                errors={formTable.errors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1" >
                        <div className="col-12 d-grid  mx-auto text-center">
                            <button className="btn btn-primary w-100" onClick={() => handlerSubmitReserveTable(data.table.id)} >Reservar mesa</button>
                        </div>
                    </div>
                </div>
            )}
        </BottomSheetModal>
    );
}

export default FormTableReservation;