import { useEffect, useState } from "react";
import { Input, Select } from '../../../screens/Layout/Forms/Form'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CompleteProfile = ({ formParam, handlerSubmit }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState({
        form: {
            name: "",
            email: "",
            birthday_date_day: "",
            birthday_date_month: "",
            birthday_date_year: "",
            gender: ""
        },
        errors: []
    })

    useEffect(() => {
        if (formParam) {
            setState({
                //...state,
                form: formParam
            })
            setIsLoading(false)
        }

    }, [formParam])



    const handleChangeInputs = (e) => {
        setState({
            ...state,
            form: {
                ...state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    const handlerSubmitInfo = () => {
        let errorArray = {}; // Define o objeto de erros
        let error = false;
        const minYear = currentYear - 15
        const currentYear = new Date().getFullYear();
        const birthYear = Number(state.form.birthday_date_year);
        const birthDay = Number(state.form.birthday_date_day);
        if (state.form.birthday_date_day.length === 0 || parseInt(state.form.birthday_date_day) <= 0 || parseInt(state.form.birthday_date_day) > 31) {
            errorArray.birthday_date_day = ['Preencha o dia do seu aniversário'];
            error = true;
        }
        if (state.form.birthday_date_month.length === 0) {
            errorArray.birthday_date_month = ['Preencha o mês do seu aniversário'];
            error = true;
        }
        if (state.form.birthday_date_year.length === 0) {
            errorArray.birthday_date_year = ['Preencha o ano do seu aniversário'];
            error = true;
        }
        if (!state.form.gender || state.form.gender.length === 0) {
            errorArray.gender = ['Preencha o seu género'];
            error = true;
        }
        const year = new Date().getFullYear()
        if (isNaN(birthYear) || birthYear > year || birthYear <= (year - 100)) {
            errorArray.birthday_date_year = ['Ano de nascimento inválido'];
            error = true;
        }

        if (birthYear > minYear) {
            errorArray.birthday_date_year = ['Deve ter pelo menos 15 anos'];
            error = true;
        }

        if (parseInt(state.form.birthday_date_day) < 0 || parseInt(state.form.birthday_date_day) > 31) {
            errorArray.birthday_date_day = ['Ano inválido'];
            error = true;
        }

        if (error) {
            toast.error("Preencha os campos obrigatórios", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setState((prevState) => ({
                ...prevState,
                errors: errorArray
            }));

            return false;
        }

        handlerSubmit(state.form); // Chama a função handlerSubmit
    };


    return (isLoading ? null : (
        <>
            <div className="row mt-3 ">
                <div className="col-12">
                    Complete o perfil para aceder ao seu Qr Code
                </div>
            </div>
            {/* <div className="row mt-3 ">
                <div className="col-12">
                    <Input
                        label="Nome"
                        name="name"
                        type="text"
                        value={state.form.name}
                        onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                        onChange={(e) => handleChangeInputs(e)}
                        errors={state.errors}
                    />
                </div>
            </div>
            <div className="row mt-3 ">
                <div className="col-12">
                    <Input
                        label="Email"
                        name="email"
                        type="email"
                        value={state.form.email}
                        onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                        onChange={(e) => handleChangeInputs(e)}
                        errors={state.errors}
                    />
                </div>
            </div> */}
            <div className="row mt-3 ">
                <div className="col-4">
                    <Input
                        label="Dia"
                        name="birthday_date_day"
                        type="number"
                        value={state.form.birthday_date_day}
                        onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                        onChange={(e) => handleChangeInputs(e)}
                        errors={state.errors}
                    />
                </div>
                <div className="col-4">
                    <Select
                        label="Mês"
                        name="birthday_date_month"
                        value={state.form.birthday_date_month}
                        onChangeOption={(e) => handleChangeInputs(e)}
                        options={[
                            { label: "Janeiro", value: "1" },
                            { label: "Fevereiro", value: "2" },
                            { label: "Março", value: "3" },
                            { label: "Abril", value: "4" },
                            { label: "Maio", value: "5" },
                            { label: "Junho", value: "6" },
                            { label: "Julho", value: "7" },
                            { label: "Agosto", value: "8" },
                            { label: "Setembro", value: "9" },
                            { label: "Outubro", value: "10" },
                            { label: "Novembro", value: "11" },
                            { label: "Dezembro", value: "12" }
                        ]}
                        style={{ textAlign: "center" }}
                        errors={state.errors}
                    />
                </div>
                <div className="col-4">
                    <Input
                        label="Ano"
                        name="birthday_date_year"
                        type="number"
                        value={state.form.birthday_date_year}
                        onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                        onChange={(e) => handleChangeInputs(e)}
                        errors={state.errors}
                    />
                </div>
            </div>
            <div className="row ">
                <div className="col-12">
                    <Select
                        label="Género"
                        name="gender"
                        value={state.form.gender}
                        onChangeOption={(e) => handleChangeInputs(e)}
                        options={[
                            { label: "Feminino", value: "FEMALE" },
                            { label: "Masculino", value: "MALE" },
                            { label: "Outro", value: "OTHER" },
                        ]}
                        style={{ textAlign: "center" }}
                        errors={state.errors}
                    />
                </div>
            </div>
            <div className="row mt-5 ">
                <div className="col-12">
                    <button className="btn btn-primary w-100" onClick={() => handlerSubmitInfo()}>Continuar</button>
                </div>
            </div>
        </>
    )
    )
}

export default CompleteProfile;